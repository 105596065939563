import requestTradingConfig from 'config/requestTrading';
import exodusInstance from 'lib/api/instances/exodus';

import {
  API_TRADING_URL,
  API_TRADING_URL_V2,
  API_EXODUS_URL,
  API_SECURITIES_GENERAL_URL,
} from 'constants/api';
import {
  IDepositGuide,
  IDepositHistory,
  IWithdrawalHistory,
} from 'features/securities/deposit/types';
import {
  IBankAccount,
  IBankAmendInfo,
  ICashWithdrawal,
  IForeignBankRules,
  ITradingBalanceDetail,
  ITransferMethod,
  ITransferOperationCheck,
  ITransferOperationResultStatus,
} from 'features/securities/withdrawal/types';
import { isRedirectToNewCore, generateUiRef } from 'features/securities/utils';

import { AuthModalStatus } from 'features/securities/AuthModal/types';
import {
  PostAuthPINResponse,
  PostAuthTokenResponse,
} from 'global/Securities/types/api/auth';
import { OtpChannel } from 'typings/auth';
import { APIResponse } from '../../../@types/api-response';

const requestNonTrading = exodusInstance.noAlert;
const requestNonTradingWithAlert = exodusInstance.defaultInstance;

const requestTrading = requestTradingConfig(undefined, false, false);
const requestTradingURLEncoded = requestTradingConfig(undefined, true, true);
const requestTradingV2 = requestTradingConfig(API_TRADING_URL_V2, false, false);

const RESET_PIN_EXODUS = `${API_EXODUS_URL}/user/setting/pin`;

/**
 * Get securities login token
 * @return {APIResponse}
 */
const getAuthToken = (): Promise<APIResponse<PostAuthTokenResponse>> =>
  requestNonTrading.get(`${API_EXODUS_URL}/sekuritas/auth/token`);

/**
 * Verify Trading PIN submitted by user
 * @param {string} loginToken - generated from postAuthToken
 * @param {string} pin - PIN submitted by user
 * @return {APIResponse}
 */
const postAuthPIN = (
  loginToken: string,
  PIN: string,
): Promise<APIResponse<PostAuthPINResponse>> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/auth/v2/login`, {
      login_token: loginToken,
      pin: PIN,
    });
  }
  return requestTradingURLEncoded.post(`${API_TRADING_URL}/auth/pin`, {
    loginToken,
    pin: PIN,
  });
};

/**
 * Get OA Status
 * @return {APIResponse}
 */
const getOAStatus = (): Promise<APIResponse<{ status: AuthModalStatus }>> =>
  requestNonTrading.get(`${API_SECURITIES_GENERAL_URL}/v2/registration/check`);

/**
 * Request Forgot Pin
 * @return {APIResponse}
 */
const requestForgotPin = (
  identity: string,
  channel: OtpChannel,
): Promise<APIResponse> =>
  requestNonTrading.post(`${RESET_PIN_EXODUS}/phone/send`, {
    identity,
    channel,
  });

/**
 * Request Forgot Pin when user not login in securitas
 * @param {string} token - generated from requestForgotPin
 * @param {string} otp - user input
 * @return {APIResponse}
 */
const verifyPin = (token: string, otp: string): Promise<APIResponse> =>
  requestNonTradingWithAlert.post(`${RESET_PIN_EXODUS}/phone/validate`, {
    token,
    otp,
  });

/**
 * Request Forgot Pin
 * @param {string} token - generated from reset pin page paramater
 * @return {APIResponse}
 */
const verifyToken = (token: string): Promise<APIResponse> =>
  requestNonTradingWithAlert.post(`${RESET_PIN_EXODUS}/email/validate`, {
    token,
  });

/**
 * Request Forgot Pin
 * @param {string} reset_pin_token - generated from verifyToken
 * @param {string} new_pin - user input
 * @return {APIResponse}
 */
const updatePin = (reset_pin_token, new_pin): Promise<APIResponse> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(`${API_TRADING_URL_V2}/auth/pin/reset`, {
      reset_pin_token,
      new_pin,
    });
  }
  return requestTradingURLEncoded.post(`${API_TRADING_URL}/auth/resetpin`, {
    reset_pin_token,
    new_pin,
  });
};

/**
 * Request Forgot Pin
 * @param {string[]} symbols - companies
 * @return {APIResponse}
 */
const getAllCorpAction = (symbols: string[]): Promise<APIResponse> =>
  requestNonTrading.get(`${API_EXODUS_URL}/corpaction/status`, {
    params: {
      symbol: symbols.join(','),
    },
  });

/**
 * Get Deposit Guide Data
 * @returns {APIResponse}
 */
const getDepositGuide = (): Promise<APIResponse<IDepositGuide>> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/deposit/guide`);
  }
  return requestTrading.get(`${API_SECURITIES_GENERAL_URL}/deposit/guide`);
};

const getAccountBankInfo = (): Promise<APIResponse<IBankAccount>> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/account/bank`);
  }
  return requestTrading.get(`${API_TRADING_URL}/account/bank`);
};

const getBankAmendInfo = (): Promise<APIResponse<IBankAmendInfo>> =>
  requestNonTrading.get(`${API_SECURITIES_GENERAL_URL}/v2/account/bank/amend`);

const getForeignBankRules = (): Promise<APIResponse<IForeignBankRules>> =>
  requestTrading.get(`${API_TRADING_URL_V2}/withdrawal/v1/foreign-rules`);

const getTradingBalanceDetail = (): Promise<
  APIResponse<ITradingBalanceDetail>
> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.get(`${API_TRADING_URL_V2}/balance/withdrawable`);
  }
  return requestTrading.get(`${API_SECURITIES_GENERAL_URL}/withdraw/balance`);
};

const cashWithdrawal = (params: {
  amount: number;
  bank_account_number: string;
  validation_pin_token: string;
  signature?: string;
  bank_swift_code?: string;
  bank_country_code?: string;
  currency_code?: string;
  transfer_type?: string;
}): Promise<APIResponse<ICashWithdrawal>> => {
  if (isRedirectToNewCore()) {
    return requestTradingV2.post(
      `${API_TRADING_URL_V2}/withdrawal/v1/withdraw`,
      {
        amount: params.amount,
        ui_ref: generateUiRef(),
        token_validate_pin: params.validation_pin_token,
        currency_code: params.currency_code,
        bank_swift_code: params.bank_swift_code,
        signature: params.signature,
        transfer_type: params.transfer_type,
      },
    );
  }

  return requestTrading.post(
    `${API_TRADING_URL}/cash/withdraw/request`,
    params,
  );
};

const getDepositHistory = (payload): Promise<APIResponse<IDepositHistory>> => {
  if (isRedirectToNewCore()) {
    const queries = new URLSearchParams({
      page: payload.page,
      limit: payload.limit,
      period: payload.period,
    });
    return requestTradingV2.get(
      `${API_TRADING_URL_V2}/history/deposit?${queries}`,
    );
  }
  return requestTrading.post(
    `${API_TRADING_URL}/cash/deposit/history`,
    payload,
  );
};

const getWithdrawalHistory = (
  payload,
): Promise<APIResponse<IWithdrawalHistory>> => {
  if (isRedirectToNewCore()) {
    const queries = new URLSearchParams({
      page: payload.page,
      limit: payload.limit,
      period: payload.period,
    });
    return requestTradingV2.get(
      `${API_TRADING_URL_V2}/history/withdraw?${queries}`,
    );
  }
  return requestTrading.post(
    `${API_TRADING_URL}/cash/withdraw/history/v2`,
    payload,
  );
};

const getTransferMethod = (
  bankCode: string,
): Promise<APIResponse<ITransferMethod>> =>
  requestTradingV2.get(`withdrawal/v1/option/${bankCode}`);

const checkTransferOperatinStatus = ({
  bankCode,
  transferType,
}: ITransferOperationCheck): Promise<
  APIResponse<ITransferOperationResultStatus>
> =>
  requestTradingV2.get(
    `withdrawal/v1/option/${bankCode}/confirmation/${transferType}`,
  );

export default {
  getAuthToken,
  postAuthPIN,
  getOAStatus,
  requestForgotPin,
  verifyPin,
  verifyToken,
  updatePin,
  getAllCorpAction,
  getDepositGuide,
  getAccountBankInfo,
  getBankAmendInfo,
  getForeignBankRules,
  getTradingBalanceDetail,
  cashWithdrawal,
  getDepositHistory,
  getWithdrawalHistory,
  getTransferMethod,
  checkTransferOperatinStatus,
};
