/* eslint-disable no-underscore-dangle */
import { AWS_AVATAR_BUCKET } from 'constants/app';
import noticeError from './logger';

let clevertapProfileQueue = [];

export function clevertapInitProfileQueue() {
  clevertapProfileQueue.map((instance) => instance());
  clevertapProfileQueue = [];
}

/**
 * this function will executes clevertap analytic based on isInitProfile
 * @param {boolean} isInitProfile
 * @param {string} username
 * @param {boolean} isVirtualTrading
 * @param {boolean} isTopUser
 * @param {boolean} isVerifiedUser
 * @param {boolean} isStockbitPro
 * @param {Date} registrationStartDate
 * @param {string} profilePictureUrl
 * @param {boolean} isSinarmasSecuritas
 * @param {string} email
 * @param {isStockbitSecuritas} isStockbitSecuritas
 * @return {void} no return after execute this function
 */
export function clevertapProfilingUser(param) {
  const {
    isInitProfile,
    identity,
    isVirtualTrading,
    isTopUser,
    isVerifiedUser,
    isStockbitPro,
    registrationStartDate,
    profilePictureUrl,
    isSinarmasSecuritas,
    email,
    isStockbitSecuritas,
    isEmitenUser = false,
  } = param;

  // Track if username is empty
  if (!identity) {
    noticeError(new Error('[CT initialization Error]: username is empty'), {
      place: 'src/utils/clevertap.ts',
      event: 'clevertapProfilingUser',
      eventParam: {
        profile: {
          identity,
          email,
        },
      },
    });
  }
  const clevertapProfilingData = {
    Name: identity,
    Identity: identity,
    'Is Virtual Trading':
      isVirtualTrading !== undefined ? isVirtualTrading : undefined,
    'Is Top User': isTopUser !== undefined ? isTopUser : undefined,
    'Is Verified User':
      isVerifiedUser !== undefined ? isVerifiedUser : undefined,
    'Is Stockbit Pro': isStockbitPro !== undefined ? isStockbitPro : undefined,
    'Registration Start Date':
      registrationStartDate !== undefined ? registrationStartDate : undefined,
    Photo:
      profilePictureUrl !== undefined
        ? `${AWS_AVATAR_BUCKET}/${profilePictureUrl}`
        : undefined,
    'Is Sinarmas Sekuritas':
      isSinarmasSecuritas !== undefined ? isSinarmasSecuritas : undefined,
    Email: email !== undefined ? email : undefined,
    'Is Stockbit Sekuritas':
      isStockbitSecuritas !== undefined ? isStockbitSecuritas : undefined,
    'Is Emiten User': isEmitenUser !== undefined ? isEmitenUser : undefined,
  };
  // filter to remove undefined value of object
  const payload = Object.keys(clevertapProfilingData).reduce((acc, key) => {
    const _acc = acc;
    if (clevertapProfilingData[key] !== undefined) {
      _acc[key] = clevertapProfilingData[key];
    }
    return _acc;
  }, {});

  const handleInitProfile = () => {
    window.clevertap?.onUserLogin.push({
      Site: payload,
    });
  };

  const handleProfilePush = () => {
    window.clevertap?.profile.push({
      Site: payload,
    });
  };

  const initProfileFunction = isInitProfile
    ? handleInitProfile
    : handleProfilePush;

  if (!window.clevertap) {
    clevertapProfileQueue.push(initProfileFunction);
    return;
  }

  initProfileFunction();
}

export function clevertapEvent(eventName, eventObject = {}) {
  if (Object.keys(eventObject).length > 0) {
    // execute this impression if impression has additional attributes
    window.clevertap?.event?.push(eventName, { ...eventObject });
  } else {
    window.clevertap?.event?.push(eventName);
  }
}

export function clevertapProfilingUserEligible({
  followers,
  following,
  posts,
  isMuted,
  isVerifiedEligible,
}) {
  const clevertapProfilingDataEligible = {
    Followers: followers !== undefined ? followers : undefined,
    Following: following !== undefined ? following : undefined,
    Posts: posts !== undefined ? posts : undefined,
    'is Muted': isMuted !== undefined ? isMuted : undefined,
    'Is Verified Badge Eligible':
      isVerifiedEligible !== undefined ? isVerifiedEligible : undefined,
  };
  // filter to remove undefined value of object
  const payload = Object.keys(clevertapProfilingDataEligible).reduce(
    (acc, key) => {
      const _acc = acc;
      if (clevertapProfilingDataEligible[key] !== undefined) {
        _acc[key] = clevertapProfilingDataEligible[key];
      }
      return _acc;
    },
    {},
  );

  window.clevertap.profile.push({
    Site: payload,
  });
}

export function academyEventAnalytic(eventName, parameter) {
  if (!eventName) {
    return;
  }

  clevertapEvent(eventName, parameter);
}

export function academyUserProfiling(data = undefined) {
  if (!data && !data.parameter) {
    return;
  }

  // filter to remove undefined value of object
  // eslint-disable-next-line array-callback-return
  const payload = Object.keys(data.parameter).reduce((acc, key) => {
    const currentAcc = acc;
    if (data[key] !== undefined) {
      currentAcc[key] = data[key];
      return currentAcc;
    }
    return null;
  }, {});

  window.clevertap?.profile.push({
    Site: payload,
  });
}

export function clearClevertapState() {
  window.clevertap?.logout();
  window.clevertap?.clear();
}
