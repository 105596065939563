/* eslint-disable import/named */
/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */
import { CancelTokenSource } from 'axios';
import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { FetchRoomsParams } from 'stores/chat/types';
import {
  GroupSettingRequestBody,
  GroupSettingResponse,
} from 'typings/api/shareTradeSetting';
import { APIResponse, BasicResponse } from '../../../@types/api-response';
import {
  BulkSharePayload,
  SendMessagePayload,
  UnreadCountPayload,
} from '../../../@types/chat';

const request = requestConfig();
const requestWithoutAlert = requestConfig(false);

const CHAT_URL = `${API_EXODUS_URL}/chat`;
const YOUTUBE_OEMBED_URL = 'https://www.youtube.com/oembed?format=json&url=';

/**
 * Get Room List
 * @param { Object } params
 * @param {string|number=} params.limit
 * @param {string|number=} params.offset
 * @param {string=} params.keyword
 * @returns {APIResponse}
 */
export const getRooms = (
  params: FetchRoomsParams,
  sourceToken?: CancelTokenSource,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/rooms`, {
    params,
    cancelToken: sourceToken?.token,
  });

/**
 * Get Requested Room List
 * @param { Object } params
 * @param {string|number=} params.limit
 * @param {string=} params.offset
 * @param {string=} params.keyword
 * @returns {APIResponse}
 */
export const getRequestedRooms = (params) =>
  request.get(`${CHAT_URL}/v2/rooms/type/invited`, { params });

/**
 * Send Chat Message
 * @param {Object} params
 * @param {string} params.content
 * @param {string=} params.attachment_url
 * @param {string|number=} params.to_user_id
 * @returns {APIResponse}
 */
const sendChat = (params): Promise<APIResponse> => {
  const data = {
    to_user_id: 0,
    attachment_url: '',
    ...params,
  };

  return request.post(`${CHAT_URL}/messages`, data);
};

/**
 * Send Bulk Share Chat Message
 * @param {Object} params
 * @param {Array} params.receivers
 * @param {string} params.text
 * @param {Object} params.shared_content
 * @param {number} params.shared_content.id
 * @param {string} params.shared_content.type
 * @param {string} params.attachment_url
 * @returns {APIResponse}
 */
const sendBulkChat = (params: BulkSharePayload): Promise<APIResponse> => {
  const data: BulkSharePayload = {
    text: '',
    ...params,
  };

  return request.post(`${CHAT_URL}/v2/messages/bulk`, data);
};

/**
 * Clear chat
 * @param {string | number} roomId
 * @returns {APIResponse}
 */
const clearChat = (roomId: string | number): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/rooms/${roomId}/clear`);

/**
 * Get all messages per chat
 * @param {string|number} roomId - Room ID
 * @param {Object=} params - Additional paramenters
 * @returns {APIResponse}
 */
export const getRoomMessage = (
  roomId: string | number,
  params?: any,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/rooms/${roomId}/messages`, { params });

/**
 * Delete Room
 * @param {string | number} roomId
 * @returns {APIResponse}
 */
const deleteChatRoom = (roomId: string | number): Promise<APIResponse> =>
  request.delete(`${CHAT_URL}/rooms/${roomId}`);

/**
 * Mark chat as read
 * @param {string|number} roomId - Room ID
 * @returns {APIResponse}
 */
export const readChat = (roomId: string | number): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/rooms/${roomId}/read`);

/**
 * Get room
 * @param {string|number} roomId
 * @returns {APIResponse}
 */
const getRoom = (roomId: string | number): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/rooms/${roomId}`);

/**
 * Accept Requested Chat
 * @param {string|number} roomId - Room ID
 * @returns {APIResponse}
 */
export const acceptRequest = (roomId) =>
  request.post(`${CHAT_URL}/rooms/${roomId}/accept`);

/**
 * Reject Requested Chat
 * @param {string|number} roomId - Room ID
 * @returns {APIResponse}
 */
export const rejectRequest = (roomId) =>
  request.post(`${CHAT_URL}/rooms/${roomId}/reject`);

const getYoutubeDetail = (youtubeUrl: string) =>
  fetch(`${YOUTUBE_OEMBED_URL}${youtubeUrl}`);

/**
 * Get policy
 * @param {string|number} roomId
 * @returns {APIResponse}
 */
const getPolicy = (params?: any): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/s3/policy`, { params });

/**
 * Forward Chat Message
 * @param {Object} params
 * @param {string} params.text
 * @param {string=} params.message_ids
 * @param {string|number=} params.receivers
 * @returns {APIResponse}
 */
const forwardChat = (params): Promise<APIResponse> => {
  const data = {
    text: '',
    ...params,
  };

  return request.post(`${CHAT_URL}/v2/messages/forward`, data);
};

/**
 * Get Suggested User
 * @param { Object } params
 * @param {string|number=} params.limit
 * @param {string|number=} params.offset
 * @param {number} params.for_group_id
 * @returns {APIResponse}
 */
export const getSuggested = (params): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/members/suggestions`, { params });

/**
 * Search Group User
 * @param { Object } params
 * @param {string} params.cursor
 * @param {string} params.keyword
 * @param {string|number=} params.per_page
 * @param {string|number=} params.by_group_id
 * @returns {APIResponse}
 */
export const getSearchGroupUser = (params): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/members/search`, { params });

/**
 * Get group messages
 * @param {string|number} roomId - Room ID
 * @returns {APIResponse}
 */
const getGroupRoom = (roomId: string | number): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/room-id/${roomId}`);

/**
 * Get group messages without alert
 * @param {string|number} roomId - Room ID
 * @returns {APIResponse}
 */
const getGroupRoomSilent = (roomId: string | number): Promise<APIResponse> =>
  requestWithoutAlert.get(`${CHAT_URL}/v2/groups/room-id/${roomId}`);

/**
 * Get personal messages
 * @param {string|number} roomId - Room ID
 * @param {Object=} params - Additional paramenters
 * @param {number=} params.limit
 * @param {string=} params.cursor_dir
 * @param {number=} params.cursor_id
 * @param {bool} params.show_stream
 * @returns {APIResponse}
 */
const getPersonalMessage = (
  roomId: string | number,
  params?: any,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/personal/${roomId}/messages`, { params });

/**
 * Get broadcast messages
 * @param {string|number} roomId - Room ID
 * @param {Object=} params - Additional paramenters
 * @param {number=} params.limit
 * @param {string=} params.cursor_dir
 * @param {number=} params.cursor_id
 * @param {bool} params.show_stream
 * @returns {APIResponse}
 */
const getBroadcastMessage = (
  roomId: string | number,
  params?: any,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/broadcast/${roomId}/messages`, { params });

/**
 * Get group messages
 * @param {string|number} roomId - Group ID
 * @param {Object=} params - Additional paramenters
 * @param {string|number=} params.limit
 * @param {string|number=} params.offset
 * @returns {APIResponse}
 */
const getGroupMessage = (
  groupId: string | number,
  params?: any,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/${groupId}/messages`, { params });

/**
 * Mute Group Notification
 * @param {string|number} roomId - Group ID
 * @param {bool} muted - Is Muted
 * @returns {APIResponse}
 */
const muteNotification = (
  groupId: string | number,
  isMuted: boolean,
): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/rooms/${groupId}/mute`, { is_muted: isMuted });

/**
 * Send Chat Message V2
 */
const sendChatV2 = (params: SendMessagePayload): Promise<APIResponse> => {
  const data = {
    text: '',
    attachment_url: '',
    ...params,
  };

  return request.post(`${CHAT_URL}/v2/messages`, data);
};

/**
 * Search Receivers
 * @param {string} keyword
 */
const getSearchReceivers = (keyword: string): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/receivers/shareable-search`, {
    params: { keyword },
  });

/**
 *  Get Memberlist
 * @param {string} keyword
 * @param {number} limit
 * @param {number} offset
 * @param {string} status
 * @param {boolean} selfExcluded
 */
export const getMemberList = (
  groupId: number,
  keyword?: string,
  limit?: number,
  offset?: number,
  status?: string,
  selfExcluded?: boolean,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/${groupId}/members`, {
    params: {
      keyword,
      limit,
      offset,
      status,
      self_exclude: selfExcluded,
    },
  });

/**
 * Update Group Detail
 * @param groupId
 * @param { Object } data
 * @param {string} params.avatar
 * @param {string} params.description
 * @param {string} params.name
 * @returns
 */
const updateChatGroup = (groupId, data): Promise<APIResponse> =>
  request.put(`${CHAT_URL}/v2/groups/${groupId}`, { ...data });

/**
 * Reset Group Chat Link Invitation
 * @param groupId
 * @returns
 */
const resetLink = (groupId): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/reset-link`);

/**
 *  Invite Member
 */
export const inviteMember = (
  groupId: number,
  userIds: number[],
): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/members/invite`, {
    user_ids: userIds,
  });

/**
 * Create Group
 *
 * @param {Object} data
 * @param {string} data.name
 * @param {string} data.description
 * @param {string} data.avatar_url
 * @param {Array} data.user_ids
 */
export const createGroup = (data): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/groups`, data);

/**
 * Find room
 * @param {string|number} userId
 * @returns {APIResponse}
 */
export const findRoom = (userId: string | number): Promise<APIResponse> =>
  requestWithoutAlert.get(`${CHAT_URL}/rooms/userid/${userId}`);

/**
 * Respond Invited Chat
 * @param {string|number} roomId - Room ID
 * @param {string} action - Action Payload
 * @returns {APIResponse}
 */
export const respondInvitation = (roomId, action) =>
  request.post(`${CHAT_URL}/v2/invitations/room-id/${roomId}/respond`, {
    action,
  });

/**
 * Assign/Remove Admin
 * @param {string|number} groupId - Group ID
 * @param {Object}        data
 * @param {string|number} data.user_id - User ID
 * @param {boolean}       data.status - Status
 * @returns {APIResponse}
 */
export const setAdminStatus = (groupId, data) =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/set-admin-status`, data);

/**
 * Leave Group
 * @param {string|number} groupId - Group ID
 * @param {Object}        data
 * @param {boolean} data.must_delete - Must Delete
 * @returns {APIResponse}
 */
export const leaveGroup = (groupId, data) =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/members/leave`, data);

/**
 * Leave Group and Change Admin
 * @param {string|number} groupId - Group ID
 * @param {Object}        data
 * @param {number[]}       data.user_id - User Id
 * @returns {APIResponse}
 */
export const leaveGroupAndChangeAdmin = (groupId, data) =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/change-admin-and-leave`, data);

/**
 * Search Receivers
 * @param {string} keyword
 * @param {string} cursor
 * @param {number} limit
 * @param {number} groupId
 */
const getSearchContactDiscovery = (
  keyword,
  cursor,
  limit,
  groupId,
): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/groups/members/suggestions/contacts`, {
    params: {
      keyword,
      limit,
      cursor,
      for_group_id: groupId,
    },
  });

/**
 * Get Max Member In Group
 * @returns {APIResponse}
 */
const getMaxMemberInGroup = (): Promise<APIResponse> =>
  requestWithoutAlert.get(`${CHAT_URL}/v2/groups/members/max`);

/**
 * Get LoggedIn Group Invitation Preview
 * @param {string} code
 * @returns {APIResponse}
 */
const getGroupInvitationPreview = (code): Promise<APIResponse> =>
  request.get(`${CHAT_URL}/v2/invitations/preview/${code}`);

/**
 * Get Guest Group Invitation Preview
 * @param {string} code
 * @returns {APIResponse}
 */
const getGroupInvitationGuestPreview = (code): Promise<APIResponse> =>
  requestWithoutAlert.get(`${CHAT_URL}/v2/invitations/public-preview/${code}`);

/**
 * Join Group
 * @param {string} code
 * @returns {APIResponse}
 */
const postJoinGroup = (code) =>
  request.post(`${CHAT_URL}/v2/invitations/join/${code}`);

/**
 * Delete Bulk Chat Message
 * @param {number=} message_ids
 * @returns {APIResponse}
 */
const deleteBulkChatMessage = (messageIds): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/messages/bulk-delete`, {
    message_ids: messageIds,
  });

/**
 * Invitation Bulk Response
 * @param {string} action
 * @param {Array} room_ids
 * @returns {APIResponse}
 */
const invitationBulkRespond = (data): Promise<APIResponse> =>
  request.post(`${CHAT_URL}/v2/invitations/bulk-respond`, { ...data });

/**
 * Delete All Invitations
 * @returns {APIResponse}
 */
const deleteAllInvitations = (): Promise<APIResponse> =>
  request.delete(`${CHAT_URL}/v2/invitations`);

/**
 * Remove Group Member
 * @param {string|number} groupId - Group ID
 * @param {Object}        data
 * @param {string|number} data.user_id - User ID
 * @returns {APIResponse}
 */
const removeGroupMember = (groupId, data) =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/members/remove`, data);

// Share Trade

const postGroupShareTradeSetting = async (
  groupId: number,
  requestBody: GroupSettingRequestBody,
): Promise<GroupSettingResponse> =>
  request.post(`${CHAT_URL}/v2/groups/${groupId}/settings`, requestBody);
  
const getUnreadCount = () =>
  request.get<BasicResponse<UnreadCountPayload>>(
    `${CHAT_URL}/v2/rooms/unread/count`,
  );

export default {
  getYoutubeDetail,
  sendChat,
  getRooms,
  getRoomMessage,
  sendBulkChat,
  deleteChatRoom,
  getRoom,
  readChat,
  clearChat,
  getRequestedRooms,
  acceptRequest,
  rejectRequest,
  getPolicy,
  forwardChat,
  getSuggested,
  getSearchGroupUser,
  getGroupRoom,
  getGroupMessage,
  muteNotification,
  sendChatV2,
  getSearchReceivers,
  getMemberList,
  updateChatGroup,
  resetLink,
  respondInvitation,
  getPersonalMessage,
  getBroadcastMessage,
  setAdminStatus,
  getSearchContactDiscovery,
  getMaxMemberInGroup,
  getGroupInvitationPreview,
  getGroupInvitationGuestPreview,
  postJoinGroup,
  deleteBulkChatMessage,
  invitationBulkRespond,
  deleteAllInvitations,
  removeGroupMember,
  getGroupRoomSilent,
  postGroupShareTradeSetting,
  getUnreadCount,
};
