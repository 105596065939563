/* eslint-disable camelcase */
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import alertMessage from 'global/AlertMessage';

// APIs
import searchApi from 'lib/api/search';
import userApi from 'lib/api/user';
import watchlistApi from 'lib/api/watchlist';

import handleErrorMessageAPI from 'global/AlertErrorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';
import GeneralEventAnalytics from 'features/chat/analytics/GeneralEventAnalytics';
import { chatAnalytics } from 'features/chat/utils';
import { effects as watchlistEffects } from './watchlist';
import { effects as moderationEffects } from './moderation';

const STATUS_FOLLOWING = {
  FOLLOW: true,
  UNFOLLOW: false,
};

const COUNT_FOLLOWING = {
  FOLLOW: 1,
  UNFOLLOW: -1,
};

export const initialState = {
  selected: {},
  detail: {},
  list: [],
  isLoading: false,
  error: null,
  message: '',
  search: {
    data: [],
    isLoading: false,
    error: null,
    message: '',
  },
  toggleFollowUser: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  toggleAlertUser: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  searchContact: {
    data: [],
    isLoading: false,
    isLoadingMore: false,
    error: null,
    message: '',
    hasMore: false,
  },
  toggleBlockUser: {
    isLoading: false,
  },
};

export const selectors = createSelector(
  (state) => state.entities.user,
  (user) => ({
    detail: user.detail,
    list: user.list,
    search: user.search,
    isLoading: user.isLoading,
    error: user.error,
    message: user.message,
    toggleFollowUser: user.toggleFollowUser,
    searchContact: user.searchContact,
    toggleBlockUser: user.toggleBlockUser,
  }),
);

const CONTEXT = '@redux/user';

const actionType = {
  SEARCH_USER: `${CONTEXT}/SEARCH_USER`,
  GET_PROFILE: `${CONTEXT}/GET_PROFILE`,
  GET_PROFILE_EXODUS: `${CONTEXT}/GET_PROFILE_EXODUS`,
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
  UNFOLLOW_USER: `${CONTEXT}/UNFOLLOW_USER`,
  SET_ALERT_USER: `${CONTEXT}/SET_ALERT_USER`,
  SEARCH_USER_CONTACT: `${CONTEXT}/SEARCH_USER_CONTACT`,
  RESET_SEARCH_USER_CONTACT: `${CONTEXT}/RESET_SEARCH_USER_CONTACT`,
  BLOCK_USER: `${CONTEXT}/BLOCK_USER`,
  UNBLOCK_USER: `${CONTEXT}/UNBLOCK_USER`,
};

// Effects -----------------------------------------------------------------
export const effects = {
  searchUser: createAsyncThunk(actionType.SEARCH_USER, async (keyword) => {
    try {
      const response = await searchApi.getSearchPeople(keyword);

      if (!response.data) {
        throw new Error('Attempt to search user failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      const peopleData = data.people;

      return { data: peopleData, message };
    } catch (error) {
      return { error };
    }
  }),

  getUserProfile: createAsyncThunk(actionType.GET_PROFILE, async (username) => {
    try {
      if (!username) {
        throw new Error('Username is required!');
      }

      const response = await userApi.getUserProfileGraph(username);

      if (!response.data) {
        throw new Error('Attempt to get user profile failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message, username };
    } catch (error) {
      return { error: error?.response?.data };
    }
  }),

  getCurrentUserProfile: createAsyncThunk(
    actionType.GET_PROFILE,
    async (username) => {
      try {
        if (!username) {
          throw new Error('Username is required!');
        }

        const response = await userApi.getCurrentUserProfileGraph();

        if (!response.data) {
          throw new Error('Attempt to get user profile failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, username };
      } catch (error) {
        return { error: error?.response?.data };
      }
    },
  ),

  setNotificationAlertUser: createAsyncThunk(
    actionType.SET_ALERT_USER,
    async ({ userid, username }) => {
      try {
        const response = await watchlistApi.setNotificationAlert(userid);

        if (!response.data) {
          throw new Error('Attempt to set notification alert failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, username };
      } catch (error) {
        return { error };
      }
    },
  ),

  searchUserContact: createAsyncThunk(
    actionType.SEARCH_USER_CONTACT,
    async ({ keyword = '', page = 1 }) => {
      try {
        const response = await searchApi.getSearchPeople(keyword, page);

        if (!response.data) {
          throw new Error('Attempt to search user failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        const peopleData = data.people;

        // tracker new message user filled
        chatAnalytics(
          () => GeneralEventAnalytics.onChatSearchUserFilled(),
          () => {},
        );

        return {
          data: peopleData,
          message,
          page,
          hasMore: data?.pagination?.has_more_users || false,
        };
      } catch (error) {
        return { error };
      }
    },
  ),

  resetSearchUserContact: createAsyncThunk(
    actionType.RESET_SEARCH_USER_CONTACT,
    () => ({ ...initialState.searchContact }),
  ),

  blockUser: createAsyncThunk(actionType.BLOCK_USER, async (params) => {
    try {
      const { userId } = params;
      const response = await userApi.blockUser(userId);

      if (!response) {
        throw new Error('Attempt to block user failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),

  unblockUser: createAsyncThunk(actionType.UNBLOCK_USER, async (params) => {
    try {
      const { userId } = params;
      const response = await userApi.unblockUser(userId);

      if (!response) {
        throw new Error('Attempt to block user failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
};

// Reducers -----------------------------------------------------------------
const reducers = {};

// Extra reducers -----------------------------------------------------------
const extraReducers = {
  [effects.searchUser.pending]: (state) => {
    state.search.isLoading = true;
    state.search.error = null;
  },
  [effects.searchUser.fulfilled]: (state, action) => {
    const { error, message, data } = action.payload;

    if (error) {
      state.search.error = error;
    } else {
      state.search.data = data;
      state.search.error = null;
    }

    state.search.isLoading = false;
    state.search.message = message;
  },
  [effects.searchUser.rejected]: (state, action) => {
    state.search.error = action.payload.error;
    state.search.isLoading = false;
  },

  [effects.getUserProfile.pending]: (state) => {
    state.isLoading = true;
    state.error = false;
  },
  [effects.getUserProfile.fulfilled]: (state, action) => {
    const { error, message, data, username } = action.payload;

    if (error) {
      state.error = error;
    } else {
      state.detail[username] = data;
      state.error = null;
    }

    state.isLoading = false;
    state.message = message;
  },
  [effects.getCurrentUserProfile.pending]: (state) => {
    state.isLoading = true;
    state.error = false;
  },
  [effects.getCurrentUserProfile.fulfilled]: (state, action) => {
    const { error, message, data, username } = action.payload;

    if (error) {
      state.error = error;
    } else {
      state.detail[username] = data;
      state.error = null;
    }

    state.isLoading = false;
    state.message = message;
  },

  // Follow unfollow user from watchlist

  [watchlistEffects.followUser.pending]: (state) => {
    state.toggleFollowUser.isLoading = true;
    state.toggleFollowUser.error = null;
  },
  [watchlistEffects.followUser.fulfilled]: (state, action) => {
    const { error, message, data, username, type } = action.payload;

    if (error) {
      state.toggleFollowUser.error = error;
    } else {
      state.toggleFollowUser.data = data;

      if (state.detail[username]?.followed !== undefined) {
        state.detail[username].followed = STATUS_FOLLOWING[type];
        state.detail[username].additional.followers =
          parseInt(state.detail[username].additional.followers, 10) +
          COUNT_FOLLOWING[type];
        state.detail[username].is_alert_enabled = STATUS_FOLLOWING[type];
      } else if (state.detail[username]?.is_followed !== undefined) {
        state.detail[username].is_followed = STATUS_FOLLOWING[type];
        state.detail[username].statistics.follower_count =
          parseInt(state.detail[username].statistics.follower_count, 10) +
          COUNT_FOLLOWING[type];
        state.detail[username].is_alert_enabled = STATUS_FOLLOWING[type];
      } else if (state.detail[username]?.status.is_followed !== undefined) {
        state.detail[username].status.is_followed = STATUS_FOLLOWING[type];
        state.detail[username].status.is_post_alert_enabled =
          STATUS_FOLLOWING[type];
        state.detail[username].statistic.follower_count +=
          COUNT_FOLLOWING[type];
      }
    }

    state.toggleFollowUser.message = message;
    state.toggleFollowUser.isLoading = false;
  },
  [watchlistEffects.followUser.rejected]: (state, action) => {
    state.toggleFollowUser.error = action.payload.error;
    state.toggleFollowUser.isLoading = false;
  },

  // Block from moderation effect
  [moderationEffects.blockUser.fulfilled]: (state, action) => {
    const { error } = action.payload;
    const userId = action.meta.arg;
    if (!error) {
      const user = Object.values(state.detail).find(
        (item) => item?.user_id === userId,
      );
      if (user) {
        state.detail[user.profile.username].status.is_blocked = true;
      }
    }
  },

  // Unblock from moderation effect
  [moderationEffects.unblockUser.fulfilled]: (state, action) => {
    const { error } = action.payload;
    const userId = action.meta.arg;
    if (!error) {
      const user = Object.values(state.detail).find(
        (item) => item?.user_id === userId,
      );
      if (user) {
        state.detail[user.profile.username].status.is_blocked = false;
      }
    }
  },

  // Set nofitication alert
  [effects.setNotificationAlertUser.pending]: (state) => {
    state.toggleAlertUser.isLoading = true;
    state.toggleAlertUser.error = null;
  },
  [effects.setNotificationAlertUser.fulfilled]: (state, action) => {
    const { error, data, message, username } = action.payload;

    if (error) {
      state.toggleAlertUser.error = error;
    } else {
      let successMessage = message;
      if (data?.alert === false) {
        successMessage = `You have unsubscribed from user @${username}`;
      }
      alertMessage({
        content: successMessage,
        alertType: 'plain',
        messageType: 'success',
        hasCloseIcon: false,
      });
      state.toggleAlertUser.data = data;
      state.detail[username].status.is_post_alert_enabled = data.alert;
    }

    state.toggleAlertUser.message = message;
    state.toggleAlertUser.isLoading = false;
  },
  [effects.setNotificationAlertUser.rejected]: (state, action) => {
    state.toggleAlertUser.error = action.payload.error;
    state.toggleAlertUser.isLoading = false;
  },
  [effects.searchUserContact.pending]: (state, action) => {
    const { page } = action.meta.arg;
    if (page === 1) {
      state.searchContact.isLoading = true;
    } else {
      state.searchContact.isLoadingMore = true;
    }
    state.searchContact.error = null;
  },
  [effects.searchUserContact.fulfilled]: (state, action) => {
    const { error, message, data, page, hasMore } = action.payload;

    if (error) {
      state.searchContact.error = error;
    } else {
      state.searchContact.hasMore = hasMore;
      if (page === 1) {
        state.searchContact.data = data;
        state.searchContact.isLoading = false;
      } else {
        state.searchContact.data.push(...data);
        state.searchContact.isLoadingMore = false;
      }
      state.searchContact.error = null;
    }

    state.searchContact.isLoading = false;
    state.searchContact.message = message;
  },
  [effects.searchUserContact.rejected]: (state, action) => {
    state.searchContact.error = action.payload.error;
    state.searchContact.isLoading = false;
  },

  [effects.resetSearchUserContact.fulfilled]: (state, action) => {
    const { message, data, hasMore } = action.payload;

    state.searchContact.data = data;
    state.searchContact.isLoading = false;
    state.searchContact.hasMore = hasMore;
    state.searchContact.message = message;
  },

  // Block un block user
  [effects.blockUser.pending]: (state) => {
    state.toggleBlockUser.isLoading = true;
  },
  [effects.blockUser.fulfilled]: (state, action) => {
    const { error } = action.payload;
    const { username } = action.meta.arg;

    if (!error && state.detail[username] && username) {
      state.detail[username].is_blocked = true;
    }

    state.toggleBlockUser.isLoading = false;
  },
  [effects.blockUser.rejected]: (state) => {
    state.toggleBlockUser.isLoading = false;
  },
  [effects.unblockUser.pending]: (state) => {
    state.toggleBlockUser.isLoading = true;
  },
  [effects.unblockUser.fulfilled]: (state, action) => {
    const { error } = action.payload;
    const { username } = action.meta.arg;

    if (!error && state.detail[username] && username) {
      state.detail[username].is_blocked = false;
    }
    state.toggleBlockUser.isLoading = false;
  },
  [effects.unblockUser.rejected]: (state) => {
    state.toggleBlockUser.isLoading = false;
  },
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers,
  extraReducers,
});

export default user;
