const SECURITIES_ACCESS_TOKEN = 'securitiesAccessToken';
const SECURITIES_REFRESH_TOKEN = 'securitiesRefreshToken';
const SECURITIES_ACCOUNT_TYPE = 'securitiesAccountType';
const SECURITIES_ACCOUNT_NUMBER = 'securitiesAccountNumber';
const TRADE_IS_LOGGED_IN = 'tradeIsLogin';
const TRADING_ACCOUNT_TYPE = 'tradingAccountType';
const LAST_TRADING_ACCOUNT_TYPE = 'lastTradingAccountType';
const TRADING_BROKER = 'tradingBroker';

export {
  SECURITIES_ACCESS_TOKEN,
  SECURITIES_REFRESH_TOKEN,
  SECURITIES_ACCOUNT_TYPE,
  SECURITIES_ACCOUNT_NUMBER,
  TRADE_IS_LOGGED_IN,
  TRADING_ACCOUNT_TYPE,
  LAST_TRADING_ACCOUNT_TYPE,
  TRADING_BROKER,
};
