export enum PaymentMethod {
  TradingBalance = 'TradingBalance',
  TradingLimit = 'TradingLimit',
  DayTrade = 'DayTrade',
}

export enum BuyOrderType {
  LimitOrder = 'LimitOrder',
  AutoBuy = 'AutoBuy',
}

export enum SellOrderType {
  LimitOrder = 'LimitOrder',
  StopLoss = 'StopLoss',
  TakeProfit = 'TakeProfit',
}

export enum SmartOrderType {
  AutoBuy = 'AutoBuy',
  StopLoss = 'StopLoss',
  TakeProfit = 'TakeProfit',
}

export enum SmartOrderCode {
  AutoBuy = 'AB',
  StopLoss = 'SL',
  TakeProfit = 'TP',
}

export enum TransactionType {
  Buy = 'Buy',
  Sell = 'Sell',
}

// Gradual migrate expiryOption from src/global/TradingModal/constant.ts:expiryOption
export enum Expiry {
  GoodForDay = 'GoodForDay',
  GoodTillCancelled = 'GoodTillCancelled',
}

export enum OrderAction {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum OrderStatus {
  Rejected = 'REJECTED',
  Open = 'OPEN',
  Amended = 'AMENDED',
  Withdrawn = 'WITHDRAWN',
  Match = 'MATCH',
  Ready = 'READY',
  Partial = 'PARTIAL',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  RequestAmend = 'REQUEST AMEND',
}

export enum SplitOrderMethodEnum {
  UNSPECIFIED = 'SPLIT_ORDER_METHOD_UNSPECIFIED',
  EQUAL = 'SPLIT_ORDER_METHOD_EQUAL',
  RANDOM = 'SPLIT_ORDER_METHOD_RANDOM_RANGE',
}
