/* eslint-disable prefer-const */
import axios from 'axios';
import { isClient, PROJECT_FOLDER } from 'constants/app';
import ERROR_MESSAGE from 'constants/errorMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import auth from 'utils/auth';

// handler
import handler from './APIErrorMessage';
import handle from './APIErrorMessage/handler';

const { APIErrorMessageHandler } = handler;

function requestNext(isShowAlertMessage: boolean = true) {
  // create new api instance
  const apiInstance = axios.create({
    baseURL: `/${PROJECT_FOLDER || ''}`,
    headers: {
      accept: 'application/json',
    },
  });

  // intercept api request
  apiInstance.interceptors.request.use(
    // onFullfilled
    (req) => {
      const parsedCookie = auth.getToken();
      const {
        accessToken: { token },
      } = parsedCookie;
      // refresh token
      if (req.headers.Authorization) {
        req.headers = {
          Authorization: req.headers.Authorization,
        };
      } else if (token) {
        // request with header accessToken
        // add access token to Authorization
        req.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      return req;
    },

    // onRejected
    (error) => Promise.reject(error),
  );

  apiInstance.interceptors.response.use(
    // on fullfilled
    async (response) => {
      const {
        data: {
          error = '',
          singleError = '',
          message = ERROR_MESSAGE.DEFAULT_ERROR_MESSAGE,
        },
      } = response;

      const isOnline = isClient() ? window?.navigator?.onLine : true;

      if (!isOnline) {
        handleErrorMessageAPI(ERROR_MESSAGE.OFFLINE, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(ERROR_MESSAGE.OFFLINE);
      }

      const errorType = error?.toUpperCase();

      // only there's an error and isShowAlertMessage = true
      if (errorType && isShowAlertMessage) {
        if (
          [ERROR_MESSAGE.UNAUTHORIZED, ERROR_MESSAGE.SERVICE_BUSY].includes(
            errorType,
          )
        ) {
          return handle.handleUnAuthorized(response.config);
        }
        APIErrorMessageHandler(errorType, message, singleError);
      }

      return response;
    },

    // on rejected

    (error) => {
      if (error.response) {
        // eslint-disable-next-line no-unused-vars
        const { status, data } = error.response;
        let {
          message,
          error: errType,
          error_type: errTypeExodus,
          singleError = '',
        } = data;

        // eslint-disable-next-line prefer-destructuring
        let errorType = (errType || errTypeExodus)?.toUpperCase();

        if (
          [ERROR_MESSAGE.UNAUTHORIZED, ERROR_MESSAGE.SERVICE_BUSY].includes(
            errorType,
          )
        ) {
          return handle.handleUnAuthorized(error.config);
        }

        if (isShowAlertMessage) {
          // the real error message is unparsed in `message`
          if (!errorType) {
            const parsedMessage = JSON.parse(data.message);
            message = parsedMessage.message;
            errorType = parsedMessage.error_type;
          }

          APIErrorMessageHandler(errorType, message, singleError);
        }
      }

      return Promise.reject(error);
    },
  );

  return apiInstance;
}

export default requestNext;
