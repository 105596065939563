/** @typedef {import('axios').AxiosResponse} AxiosResponse */
/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */

import { API_EXODUS_URL } from 'constants/api';
import exodusInstance from 'lib/api/instances/exodus';
import { ProfileInfo } from 'typings/profile/ProfileInfo';
import { APIResponse } from '../../../@types/api-response';

const requestExodus = exodusInstance.defaultInstance;
const requestExodusNoAlert = exodusInstance.noAlert;

// Configs
const USER_URL = '/user';
const USER_PROFILE_URL = `${USER_URL}/profile`;
const USER_EXODUS_URL = `${API_EXODUS_URL}/user`;

// onboarding
const USER_ONBOARDING = `${USER_URL}/onboarding`;
const USER_CONNECT_SOCIAL_MEDIA = `${USER_URL}/v2/connect`;
const USER_DISCONNECT_SOCIAL_MEDIA = `${USER_URL}/disconnect`;
// avatar
const USER_AVATAR3D_LIST_URL = `${USER_URL}/avatar/collection`;

// Block
/**
 * Block user by User id.
 * @param {string} userId - string of user id
 * @returns {APIResponse}
 */
const blockUser = (userId) =>
  requestExodus.post(`${USER_EXODUS_URL}/${userId}/block`);

/**
 * Block user by User id.
 * @param {string} userId - string of user id
 * @returns {APIResponse}
 */
const unblockUser = (userId) =>
  requestExodus.post(`${USER_EXODUS_URL}/${userId}/unblock`);

/**
 * Get list of blocked users
 * @param {number} page - target page
 * @returns {APIResponse}
 */
const getBlockedUser = (page = 1) =>
  requestExodusNoAlert.get(`${USER_EXODUS_URL}/blocked`, { params: { page } });

// User Profile
/**
 * Edit user profile.
 * @param {string} userId - string of user id
 * @param {string} website - string of website url
 * @param {string} gender - string of user gender
 * @param {string} about - string of user descriptions
 * @param {string} avatar - string of avatar url
 * @param {string} fullname - string of user's fullname
 * @param {string} email  - string of user's email
 * @param {string} exchange - string of user's exchange
 */

const editUserProfile = ({
  website,
  gender,
  about,
  avatar,
  fullname,
  email,
  exchange,
  ...params
}) =>
  requestExodus.put(`${USER_EXODUS_URL}/profile`, {
    website,
    gender,
    about,
    avatar,
    fullname,
    email,
    exchange,
    ...params,
  });

/**
 * Get list of trending user by range of time
 * @param {string} timeRange - range of time indicator ['1W']
 * @returns {APIResponse}
 */

const getTrendingUser = (timeRange = '1W') =>
  requestExodusNoAlert.get(`${USER_EXODUS_URL}/trending`, {
    params: { key: timeRange },
  });

/**
 * Get user profile by username
 * @param {string} username - exact username of user
 * @return {AxiosResponse}
 */
const getUserProfile = (username) =>
  requestExodusNoAlert.get(`${USER_PROFILE_URL}/${username}`);

/**
 * Get user profile graph by username
 * @param {string} username - exact username of user
 * @return {AxiosResponse}
 */
const getUserProfileGraph = (username) =>
  requestExodusNoAlert.get(`${API_EXODUS_URL}/usergraph/socialinfo/${username}`);

const getGuestUserProfileGraph = (username: string) =>
  requestExodus.get(
    `${API_EXODUS_URL}/usergraph/non-login/socialinfo/${username}`,
  );

/**
 * Get current user profile graph
 * @return {AxiosResponse}
 */
const getCurrentUserProfileGraph = () =>
  requestExodusNoAlert.get(`${API_EXODUS_URL}/usergraph/socialinfo/user/me`);

/**
 * Get user profile by username
 * @param {string} username - exact username of user
 * @return {AxiosResponse}
 */
const getUserProfileExodus = (username): Promise<APIResponse<ProfileInfo>> =>
  requestExodusNoAlert.get(`${USER_EXODUS_URL}/profile/${username}`);

/**
 * retrieveUploadToken
 * to skip onboarding process
 */
const retrieveUploadToken = (fileName) =>
  requestExodusNoAlert.post(`${USER_URL}/upload/token`, { filename: fileName });

/**
 * userSkipOnboarding
 * to skip onboarding process
 * @param {number} step - next step onboarding
 */
const userSkipOnboarding = (step) =>
  requestExodus.post(`${USER_ONBOARDING}`, { step });

/**
 * userConnectSocialMedia
 * to connect user to social media account
 * @param {string} socialMediaName - social media's name
 * @param {object} requestBody - spcial media's object needed
 */
const userConnectSocialMedia = (socialMediaName, requestBody) => {
  return requestExodus.post(
    `${USER_CONNECT_SOCIAL_MEDIA}/${socialMediaName}`,
    requestBody,
  );
};

/**
 * Disconnect social media from user account
 * @param {string} socialMediaName
 * @return {APIResponse}
 */
const disconnectSocialMedia = (socialMediaName, requestBody) =>
  requestExodus.post(
    `${USER_DISCONNECT_SOCIAL_MEDIA}/${socialMediaName}`,
    requestBody,
  );
/**
 * Mute user
 * @param {Object} suspendData - The data sent when suspend user
 * @param {number} suspendData.userid - User ID
 * @param {number} suspendData.postid - User ID
 * @param {number} suspendData.blockmode - Type of suspension
 * @param {string} suspendData.reason - Mute reason
 * @param {string} suspendData.duration - Mute duration
 * @param {boolean} suspendData.sendEmail - Send notification of being mute to user
 * @param {boolean} suspendData.blockIp - Block IP permanently from system
 *
 * @return {AxiosResponse}
 */
const suspendUser = ({
  userid,
  postid,
  blockmode,
  reason,
  duration,
  sendEmail,
  blockIp,
}) => {
  const data = {
    type: blockmode,
    user_id: userid,
    reason,
    days: duration,
    stream_id: postid,
    send_email: sendEmail,
    block_ip: blockIp,
  };

  return requestExodus.post(`${USER_URL}/admin/suspend`, data);
};

/**
 * Get Avatar3D List
 * @returns {AxiosResponse}
 */
const getAvatar3DList = () => requestExodusNoAlert.get(`${USER_AVATAR3D_LIST_URL}`);

const updatePreference = (data) =>
  requestExodus.put(`${USER_EXODUS_URL}/profile/country`, data);

// Exports ---------------------------------------------------------------------
export default {
  blockUser,
  unblockUser,
  getBlockedUser,
  editUserProfile,
  getTrendingUser,
  getUserProfile,
  getUserProfileExodus,
  getUserProfileGraph,
  getGuestUserProfileGraph,
  getCurrentUserProfileGraph,
  retrieveUploadToken,
  userSkipOnboarding,
  userConnectSocialMedia,
  disconnectSocialMedia,
  suspendUser,
  getAvatar3DList,
  updatePreference,
};
